import React from "react";
import { graphql } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import { Link } from "gatsby";
import {
  Accordion,
  AccordionItem,
  AccordionItemButton,
  AccordionItemState,
  AccordionItemPanel,
} from "react-accessible-accordion";

import Layout from "../components/Layout";
import SearchEngineOptimization from "../components/SEO";
import Testimonials from "../components/Repeating/Testimonials";
import PracticeAreasSidebar from "../components/Repeating/PracticeAreasSidebar";
import Attorneys from "../components/Repeating/Attorneys";
import WhyUs from "../components/Repeating/WhyUs";
import CallToAction from "../components/Repeating/CTA";
import ButtonSolid from "../components/Button/ButtonSolid";

const Page = ({ data }) => {
  const accordionContent = [
    {
      title: "Trust",
      content: (
        <>
          <p>
            A trust is a written instrument that creates a legal entity that
            holds assets for beneficiaries and sets forth a trustee to manage
            such assets, the powers of the trustee, and the rights of the
            beneficiaries. A trust takes effect during the lifetime of the
            creator of a trust and is intended to avoid probate for any assets
            that are titled in the name of the trust or payable thereto. The
            existence of a trust must be revealed to beneficiaries and heirs
            when the terms become irrevocable. The terms usually become
            irrevocable when the creator of the trust dies.
          </p>
        </>
      ),
    },
    {
      title: "Trustor",
      content: (
        <>
          <p>
            This is the person or entity that created the trust. A trustor may
            also be referred to as a settlor or grantor.
          </p>
        </>
      ),
    },
    {
      title: "Trustee",
      content: (
        <>
          <p>
            A trustee is the person or entity that holds the trust property.
            They’re responsible for managing the trust for the beneficiary
            according to the terms of the trust.
          </p>
        </>
      ),
    },
    {
      title: "Fiduciary",
      content: (
        <>
          <p>
            A fiduciary is a person or entity that has a legal obligation to
            manage property on behalf of someone else. A fiduciary is held to a
            high standard of conduct and must carry out specific fiduciary
            duties.
          </p>
        </>
      ),
    },
    {
      title: "Beneficiary",
      content: (
        <>
          <p>
            A beneficiary is the person or entity for whose benefit the trust
            was created.
          </p>
        </>
      ),
    },
    {
      title: "Fiduciary Duties",
      content: (
        <>
          <p>
            This refers to the duty or obligation to act in the best interests
            of the beneficiary or heir.
          </p>
        </>
      ),
    },
    {
      title: "Breach",
      content: (
        <>
          <p>
            A breach is when a fiduciary fails to fulfill their fiduciary
            duties.
          </p>
        </>
      ),
    },

    {
      title: "Administration",
      content: (
        <>
          <p>This refers to the process of managing the estate.</p>
        </>
      ),
    },
    {
      title: "Petition",
      content: (
        <>
          <p>
            A petition is a formal written request to the court asking for a
            specific court order.
          </p>
        </>
      ),
    },
    {
      title: "Will Contest",
      content: (
        <>
          <p>
            This refers to when an interested party disputes the validity of a
            will.
          </p>
        </>
      ),
    },
  ];

  return (
    <Layout>
      <SearchEngineOptimization
        title="Trust & Estate Litigation Lawyer San Diego | Brierton Jones"
        description="Brierton, Jones & Jones, LLP will connect you with the perfect estate and trust litigation lawyer in San Diego for your needs. Schedule a consultation today!"
        openGraphImage={data.openGraphImage.publicURL}
        twitterOpenGraphImage={data.twitterOpenGraphImage.publicURL}
      />
      <section className="bg-gray-50 pb-6 pt-12 md:pb-16 md:pt-10">
        <div className="relative">
          <div className="container">
            <div className="grid items-center gap-y-14 md:min-h-[506px] md:grid-cols-2">
              <div className="md:pr-6 lg:pr-12">
                <div className="mb-6 text-sm font-bold uppercase tracking-wide text-gray-300">
                  RESOLVE THE CONFLICT
                </div>
                <h1>San Diego Trust Litigation Lawyer</h1>
                <p>
                  Trust and estate cases are often legally complex and uniquely
                  emotional. As a result, informal discussions can quickly break
                  down, and sometimes people will try to take advantage of the
                  situation. At Brierton, Jones & Jones, LLP, we have handled
                  these types of cases for decades and have a broad range of
                  expertise in trust and estate litigation.
                </p>
                <ButtonSolid
                  modal="modal-contact"
                  text="Schedule a Consultation"
                />
              </div>

              <div className="-mr-4 md:absolute md:right-0 md:top-0 md:mr-0 md:h-full md:w-[50vw] md:pl-6 lg:pl-12">
                <StaticImage
                  src="../images/8.0 Trust Litigation/1.0 Hero desktop.jpg"
                  loading="eager"
                  className="h-full rounded-l-[90px]"
                  imgClassName="object-left"
                />
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="mb-20 pt-14 md:mb-32 md:pt-20">
        <div className="container">
          <div className="flex justify-between md:space-x-14 lg:space-x-28">
            <div>
              <div className="mb-12 md:mb-18">
                <h2>When Is It Wise To Hire a Trust Litigation Lawyer?</h2>
                <p>
                  A trust is when a third party (called a ‘trustee’) is given
                  the legal right to hold and manage your assets after you pass
                  away.
                </p>
                <p>
                  It’s best to seek help from an experienced trust and estate
                  litigation attorney when:
                </p>
                <ul className="mb-6 list-disc pl-6">
                  <li>A trust is being contested</li>
                  <li>
                    A trustee is not fulfilling their legal obligations (a
                    breach of their fiduciary duty)
                  </li>
                  <li>The accountings are being contested </li>
                  <li>A creditor’s claim has been rejected </li>
                  <li>
                    There are disputes over a{" "}
                    <a href="https://briertonjones.com/conservatorship-lawyer/">
                      conservatorship
                    </a>{" "}
                    or{" "}
                    <a href="https://briertonjones.com/guardianship-lawyer/">
                      guardianship
                    </a>
                  </li>
                  <li>
                    There are claims of undue influence, lack of capacity, or
                    elder abuse
                  </li>
                </ul>
              </div>

              <div>
                <h3>Understanding the Terminology</h3>
                <p>
                  If you’re considering getting legal help from a trust
                  litigation lawyer, here are some of the common terms to
                  familiarize yourself with:
                </p>
                <Accordion
                  allowZeroExpanded={true}
                  className="flex flex-col space-y-5 border-t border-gray-100 pt-5"
                >
                  {accordionContent.map((item, i) => (
                    <AccordionItem
                      uuid={i}
                      className="border-b border-gray-100 pb-5"
                    >
                      <AccordionItemButton className="flex items-center justify-between focus:outline-none">
                        <AccordionItemState>
                          {(state) => {
                            return (
                              <>
                                <h3 className="mb-0 font-display text-xl font-medium text-typography-heading no-underline">
                                  {item.title}
                                </h3>
                                <i
                                  className={`far ml-2 text-xl text-seafoam ${
                                    state.expanded
                                      ? "fa-minus"
                                      : "fa-chevron-down"
                                  }`}
                                ></i>
                              </>
                            );
                          }}
                        </AccordionItemState>
                      </AccordionItemButton>

                      <AccordionItemPanel className="pt-5">
                        {item.content}
                      </AccordionItemPanel>
                    </AccordionItem>
                  ))}
                </Accordion>
              </div>
            </div>
            <div className="hidden md:block">
              <PracticeAreasSidebar activeLink={7} />
            </div>
          </div>
        </div>
      </section>

      <Attorneys />
      <WhyUs />
      <Testimonials />
      <CallToAction text="If litigation is necessary to resolve your case, it’s important not to delay. Our team of trust litigation lawyers in San Diego can help and answer all your questions." />
    </Layout>
  );
};

export const query = graphql`
  {
    openGraphImage: file(
      relativePath: {
        eq: "Global/Open Graphs/Facebook/08 Trust Litigation.jpg"
      }
    ) {
      publicURL
    }
    twitterOpenGraphImage: file(
      relativePath: { eq: "Global/Open Graphs/Twitter/08 Trust Litigation.jpg" }
    ) {
      publicURL
    }
  }
`;

export default Page;
